import React, { useState } from 'react';
import foto from './assets/Foto.png';
//import logoEmergya from './assets/logoemergya.png';
import logoUpo from './assets/logoupo.png';
import googleCloud from './assets/googleCloud.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Navigation from './Navbar';
import ContactForm from './Form';
import CookieConsent from "react-cookie-consent";
function renderContactButtons() {
  return <>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
    <a href="tel:+34662355894" className="mobile hidden-mobile" target="_blank">
      <i className="fa fa-phone mobile-float"></i>
    </a>
    <a href="https://api.whatsapp.com/send?phone=34662355894&amp;text=Hola,%20quisiera%20información%20sobre%20el%20Máster%20en%20Arquitectura%20y%20Desarrollo%20de%20Sistemas%20Cloud" className="whatsapp" target="_blank">
      <i className="fa fa-whatsapp whatsapp-float"></i>
    </a>
  </>;
}
function renderLandpage() {
  return <>
    <div id="inicio" className="page-header">
      <Container>
        <Row className="align-items-center">
          <Col lg={9}>
            <div className="content-center">
              <div className="title-brand">
                <h1 className="presentation-title">Máster en Arquitectura y Desarrollo de Sistemas Cloud</h1>
              </div>
              <h2 className="presentation-subtitle"> Lidera proyectos de creación de soluciones TI basadas en cloud empleando Google Cloud Platform, así como el despliegue y gestión de las mismas. </h2>
              <div className="buttons">
                <Button href="#contacto" title="Quiero más información" > Quiero más información </Button>
                <Button href="https://www.upo.es/formacionpermanente/master-formacion-permanente/arquitectura-y-desarrollo-de-sistemas-cloud/" title="Plan de estudios" target="_blank" rel="noreferrer" className="btn-download"> <i className="far fa-sticky-note"></i> Plan de estudios </Button>
              </div>
            </div>
          </Col>
          <Col>
            <div className="content-right">
              <ul>
                <li>
                  <i className="fa fa-calendar" /> Del 15 de noviembre de 2024 al 4 de julio de 2025
                </li>
                <li>
                  <i className="fa fa-clock" /> Sesiones online, viernes de 16h a 21h
                </li>
                <li>
                  <i className="fa fa-euro-sign" /> 4.500 euros
                </li>
                <li><a href="https://www.upo.es/formacionpermanente/master-formacion-permanente/arquitectura-y-desarrollo-de-sistemas-cloud/" target="_blank" rel="noreferrer" title="Programa de pagos">Programa de pagos fraccionados</a></li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}

function renderEnQueConsiste() {
  return <>
    <div id="enqueconsiste"></div>
    <div className="page-consiste">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <h2>En qué consiste</h2>
            <p>Este programa formativo se centra en el ecosistema de <strong>Google Cloud Platform</strong>, ofreciendo la especialización profesional de Cloud Architect, <strong>muy demandada en el ámbito de las TICs</strong>. Para ello, el claustro se compone de expertos en Arquitectura Cloud, procedentes de empresas referentes en la industria como son <strong>Google y <a title="Link a la ficha de partner de Emergya" target="_blank" rel="noreferrer" href="https://cloud.withgoogle.com/partners/detail/?id=emergya-digital&hl=es-ES">su <i>premier partner</i> Emergya</a></strong>.</p>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}

function renderObjetivos() {
  return <>
    <div id="objetivos"></div>
    <div className="page-objetivos">
      <Container>
        <Row className="align-items-center">
          <Col lg={7}>
            <div className="content-left">
              <h2>Lo que aprenderás de Arquitectura Cloud</h2>
              <ul>
                <li><i className="fas fa-check"></i> Diseñar soluciones y arquitecturas cloud.</li>
                <li><i className="fas fa-check"></i> Dimensionar, parametrizar y gestionar recursos de computación cloud.</li>
                <li><i className="fas fa-check"></i> Crear soluciones escalables y seguras.</li>
                <li><i className="fas fa-check"></i> Desarrollo de sistemas para la gestión y el análisis de información en Google Cloud Platform.</li>
                <li><i className="fas fa-check"></i> Propuesta de soluciones de negocio basadas en Google Cloud Platform.</li>
              </ul>
              <p><a href="https://www.youtube.com/watch?v=JtUIQz_EkUw" target="_blank" rel="noreferrer" title="¿Qué es Google Cloud Platform (GCP)?">¿Qué es Google Cloud Platform (GCP)?</a></p>
            </div>
          </Col>
          <Col lg={5}>
            <div className="content-right">
              <iframe width="560" height="350" src="https://player.vimeo.com/video/570247983?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="CSMD-video-promocion" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}

function renderParaQuien() {
  return <>
    <div id="paraquien" className="page-paraquien">
      <Container fluid>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="content-left">
                <img src={foto} alt="Para quién"></img>
              </div>
            </Col>
            <Col lg={7}>
              <div className="content-right">
                <h2>¿A quién se dirige este Máster?</h2>
                <p>Este título está dirigido a profesionales del sector de la informática, así como titulados sin experiencia previa en titulaciones afines al sector: ingenieros en informática, ingenieros técnicos en informática, ingenieros en telecomunicaciones, graduados en matemáticas, etc.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="pillsWrapper">
        {renderPills()}
      </Container>
    </div>
  </>;
}


function renderPills() {
  return (<Row className="pills">
    <Col className="perfil">
      <div className="wrap">
        <h4>Perfil de acceso</h4>
        <p>Dos vías de acceso al Máster para que tengas tu oportunidad:</p>
        <ul>
          <li>Recién titulados universitarios y no universitarios.</li>
          <li>Personas procedentes del mercado laboral con amplia experiencia profesional.</li>
        </ul>
        <p>Ambos perfiles obtendrán la titulación correspondiente en Arquitectura Cloud.</p>
        <span className="link"><a href="https://www.upo.es/formacionpermanente/master-formacion-permanente/arquitectura-y-desarrollo-de-sistemas-cloud/" target="_blank" rel="noreferrer" title="Requisitos de acceso"><i className="far fa-sticky-note"></i> Requisitos de acceso</a></span>
      </div>
    </Col>
    <Col className="salidas">
      <div className="wrap">
        <h4>Salidas profesionales</h4>
        <p>El Cloud Computing es una de las áreas que más salidas profesionales tendrá a medio o largo plazo (<a href="http://www3.weforum.org/docs/WEF_Jobs_of_Tomorrow_2020.pdf" target="_blank" rel="noreferrer" title="Jobs of tomorrow (enero 2020)">Jobs of tomorrow, enero 2020</a>).</p>
        <p>Los expertos en cloud pueden desempeñar roles de administrador o arquitecto de sistemas cloud, data engineer, consultor o arquitecto de integración con sistemas cloud, etc.</p>
        <span className="link"><a href="https://www.upo.es/postgrado/Master-en-Arquitectura-y-Desarrollo-de-Sistemas-Cloud/#practicas-externas" target="_blank" rel="noreferrer" title="Plan de práćticas"><i className="far fa-sticky-note"></i> Programa de prácticas</a></span>
      </div>
    </Col>
    <Col className="mercado">
      <div className="wrap">
        <h4>Alta demanda en el mercado</h4>
        <p>Según un <a href="https://www.fundacionbankinter.org/ftf/tendencias/cloud-computing/futuro-cloud-computing/futuro-cloud-computing#:~:text=El%20futuro%20nos%20depara%3A,Cloud%20Computing%20para%20distintos%20cometidos" target="_blank" rel="noreferrer" title="informe de la Fundación Bankinter">informe de la Fundación Bankinter</a> el mercado del Cloud Computing crecerá alrededor de un 20% anual en los próximos años.</p><p>De hecho, sólo en España, entre 2018 y 2019 la demanda de expertos en cloud aumentó un 48,78%, debido a la creciente digitalización de las empresas.</p>
        <span className="link"><a href="https://cloud.google.com/free/docs/aws-azure-gcp-service-comparison?utm_source=google&utm_medium=blog&utm_campaign=FY21-Q2-Product-Mapping-Blog&utm_content=documentation" target="blank" title="Diferencias entre GCP y otras plataformas"><i className="far fa-sticky-note"></i> Diferencias entre GCP y otras plataformas</a></span>
      </div>
    </Col>
  </Row>);
}

function renderOportunidadesEmpleo() {
  return <>
    <div id="oportunidades-de-empleo" className="page-oportunidades-empleo">
      <div className="ball-verde"></div>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <h2>Oportunidades de empleo</h2>
            <p>El alumnado podrá acogerse a los distintos programas de prácticas existentes dirigidos tanto a estudiantes como titulados/as y gestionados por la <a href="https://www.upo.es/fundaciones/fundacion-universidad-pablo-de-olavide/" target="_blank" rel="noreferrer" title="Fundación Universidad Pablo de Olavide">Fundación Universidad Pablo de Olavide</a></p>
            <p>La empresa colaboradora, <a title="Emergya" target="_blank" rel="noreferrer" href="https://www.emergya.com">Emergya</a>, ofrecerá a los egresados del Máster, en función de su rendimiento y experiencia, <strong>vacantes dentro de sus equipos de Desarrollo y Arquitectura Cloud y de AI/Data.</strong></p>
            <div className="buttons"><Button href="#contacto" title="Quiero más información" > Quiero más información </Button></div>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}

function renderComoFunciona() {
  return <>
    <div id="comofunciona" className="page-comofunciona">
      <div className="circle-rosa"></div>
      <div className="nube"></div>
      <Container fluid>
        <Container>
          <h2>Qué estudiarás en el Máster</h2>
          <p>El Máster incluye un amplio programa formativo que abarca todas las áreas del cloud con especial atención al entorno de Google Cloud Platform. Las clases se desarrollarán de forma online, a través de sesiones síncronas online con los docentes junto al trabajo autónomo del material didáctico por parte del alumno gracias a la metodología eLearning. Los módulos en que se divide el programa son:</p>
        </Container>
        <Row>
          <Col className="temario" lg="6">
            <ul>
              <li>Introducción a Google Cloud Platform</li>
              <li>Recursos de computación y almacenamiento</li>
              <li>Seguridad en Google Cloud Platform</li>
              <li>Almacenamiento en Google Cloud Platform</li>
              <li>Data Analysis y Visualization</li>
              <li>Kits de desarrollo de código de Google</li>
              <li>Desarrollo de aplicaciones cloud</li>
              <li>Desarrollo de Machine Learning</li>
              <li>Casos de uso - Diseñando soluciones</li>
              <li>Trabajo fin de Máster</li>
            </ul>
            <p className="link"><a href="https://www.upo.es/formacionpermanente/master-formacion-permanente/arquitectura-y-desarrollo-de-sistemas-cloud/" target="_blank" rel="noreferrer" title="Plan de estudios"><i className="far fa-sticky-note"></i> Temario completo</a></p>
          </Col>
          <Col lg={6} className="temarioVideo">
            <iframe width="560" height="315" src="https://player.vimeo.com/video/599489060?h=f4f0d2bc7d" title="CSMD-video-promocion" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p><i>La metodología te abrirá las puertas del mercado laboral.</i></p>
            <p><i>Aprende las competencias más demandadas.</i></p>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}

function renderDocentes() {
  return <>
    <div id="docentes" className="page-docentes">
      <Container fluid className="docentes-header">
        <div className="text-wrap">
          <h2>¿Quién lo imparte?</h2>
          <p>El claustro de profesorado está compuesto por un gran número de expertos procedentes de la industria, con grandes referentes como <strong>Google y su <i>premier partner</i> <a href="https://www.emergya.com" taget="_blank">Emergya</a></strong>.</p>
        </div>
      </Container>
      <Container className="d-flex flex-wrap">
        <div className="docente">
          <img src="./profesores/cbarranco.jpeg" alt="Carlos Barranco"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/cbarranco1/" title="Carlos Barranco" target="_blank" rel="noreferrer">Carlos Barranco</a></p>
            <p className="cargo">Especialista en Gestión de Datos Masivos en Universidad Pablo Olavide.</p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/amoron.png" alt="Alejandro Gómez Morón"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/agomezmoron/" title="Alejandro Gómez Morón" target="_blank" rel="noreferrer">Alejandro Gómez Morón</a></p>
            <p className="cargo">IT Director at GoldenRace</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Associate Engineer" href="https://cloud.google.com/certification/cloud-engineer">Associate Cloud Engineer</a> & <a target="_blank" rel="noreferrer" title="Professional Cloud Architect" href="https://cloud.google.com/certification/cloud-architect">Professional Cloud Architect</a>  </p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/jrodriguez.jpeg" alt="Julio Rodriguez Gonzalez"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/julio-rodriguez-6b480443/" title="Julio Rodriguez Gonzalez" target="_blank" rel="noreferrer">Julio Rodriguez Gonzalez</a></p>
            <p className="cargo">Senior Cloud Consultant, Professional Services Organization en Google.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Professional Security Engineer" href="https://cloud.google.com/certification/cloud-security-engineer">Professional Security Engineer</a></p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/ndiaz.jpeg" alt="Norberto Díaz Díaz"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/norberto-diaz-diaz/" title="Norberto Díaz Díaz" target="_blank" rel="noreferrer">Norberto Díaz Díaz</a></p>
            <p className="cargo">Especialista en aplicación de técnicas de Inteligencia Artificial.</p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/sgarcia.jpeg" alt="Sebastián García Anderman"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/sganderman/" title="Sebastián García Anderman" target="_blank" rel="noreferrer">Sebastián García Anderman</a></p>
            <p className="cargo">Big Data and Analytics Specialist en Google.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Associate Engineer" href="https://cloud.google.com/certification/cloud-engineer">Associate Engineer</a>, <a target="_blank" rel="noreferrer" title="Professional Data Engineer" href="https://cloud.google.com/certification/data-engineer">Professional Data Engineer</a>, <a target="_blank" rel="noreferrer" title="Professional Security Engineer" href="https://cloud.google.com/certification/cloud-security-engineer">Professional Security Engineer</a> & <a target="_blank" rel="noreferrer" title="Professional Cloud Architect" href="https://cloud.google.com/certification/cloud-architect">Professional Cloud Architect</a>  </p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/pbalm.jpeg" alt="Paul Balm"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/paulbalm/" title="Paul Balm" target="_blank" rel="noreferrer">Paul Balm</a></p>
            <p className="cargo">Strategic Cloud Engineer en Google.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Associate Engineer" href="https://cloud.google.com/certification/cloud-engineer">Associate Engineer</a>, <a target="_blank" rel="noreferrer" title="Professional Data Engineer" href="https://cloud.google.com/certification/data-engineer">Professional Data Engineer</a>, <a target="_blank" rel="noreferrer" title="Machine Learning Engineer" href="https://cloud.google.com/certification/machine-learning-engineer">Machine Learning Engineer</a> </p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/iiglesias.jpeg" alt="Ildefonso Iglesias Escribano"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/ildefonso-iglesias-escribano/" title="Ildefonso Iglesias Escribano" target="_blank" rel="noreferrer">Ildefonso Iglesias Escribano</a></p>
            <p className="cargo">Gestor de Proyectos, Arquitecto de Soluciones y responsable AI/Data en Emergya.</p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/jamores.png" alt="Julio Amores Pérez"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/julio-amores-p%C3%A9rez-3aaaa4121" title="Julio Amores Pérez" target="_blank" rel="noreferrer">Julio Amores Pérez</a></p>
            <p className="cargo">Specialist Solution Architect Lead en Vodafone.</p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/ajgerena.jpeg" alt="Antonio José Gerena Román"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/antonio-gerena/" title="Antonio José Gerena Román" target="_blank" rel="noreferrer">Antonio José Gerena Román</a></p>
            <p className="cargo">Site Reliability Engineer en Ecosia.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Associate Engineer" href="https://cloud.google.com/certification/cloud-engineer">Associate Engineer</a>
              & <a target="_blank" rel="noreferrer" title="Professional Cloud Architect" href="https://cloud.google.com/certification/cloud-architect">Professional Cloud Architect</a>  </p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/ocastano.png" alt="Óscar Castaño Calle"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/%C3%B3scar-casta%C3%B1o-calle-00008061/" title="Óscar Castaño Calle" target="_blank" rel="noreferrer">Óscar Castaño Calle</a></p>
            <p className="cargo">Google Cloud Architect y experto en Dialogflow en Emergya.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Professional Cloud Architect" href="https://cloud.google.com/certification/cloud-architect">Professional Cloud Architect</a>  </p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/piglesias.jpeg" alt="Pablo Iglesias de Lomas"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/pablo-iglesias/" title="Pablo Iglesias de Lomas" target="_blank" rel="noreferrer">Pablo Iglesias de Lomas</a></p>
            <p className="cargo">Software Architect y experto en Dialogflow en Emergya.</p>
          </div>
        </div>
        {/*<div className="docente">
          <img src="./profesores/rramos.jpeg" alt="Rogelio Ramos Bellido"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/rogelio-ramos/" title="Rogelio Ramos Bellido" target="_blank" rel="noreferrer">Rogelio Ramos Bellido</a></p>
            <p className="cargo">Software Developer y DevOps especializado en Cloud en Pod Group By G.D.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Associate Engineer" href="https://cloud.google.com/certification/cloud-engineer">Associate Engineer</a></p>
          </div>
        </div>
        <div className="docente">
          <img src="https://dummyimage.com/300x300/948794/636ded.png" alt="docente"></img>
          <div className="docente-wrap">
            <p className="nombre">Carlos Jesús Sánchez Ortega</p>
            <p className="cargo">DevOps Enginner enfocado a la automatización en entornos Cloud en Emergya.</p>
                    </div>*/}
        <div className="docente">
          <img src="./profesores/acuesta.jpg" alt="Ángel de la Cuesta"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/angel-de-la-cuesta/" title="Ángel de la Cuesta" target="_blank" rel="noreferrer">Ángel de la Cuesta</a></p>
            <p className="cargo">Data Scientist en Coverwallet.</p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/ernesto.jpg" alt="Ernesto Vázquez"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/ernesto-daniel-v%C3%A1zquez-aldama-595b11189" title="Ernesto Vázquez" target="_blank" rel="noreferrer">Ernesto Vázquez</a></p>
            <p className="cargo">DevOps Engineer en Emergya.</p>
          </div>
        </div>
        <div className="docente">
          <img src="./profesores/pablo.jpg" alt="Pablo Salvatierra"></img>
          <div className="docente-wrap">
            <p className="nombre"><a href="https://www.linkedin.com/in/pablo-salvatierra-martinez/?originalSubdomain=es" title="Pablo Salvatierra" target="_blank" rel="noreferrer">Pablo Salvatierra</a></p>
            <p className="cargo">DevOps Engineer en Emergya.</p>
            <p className="certificaciones"><a target="_blank" rel="noreferrer" title="Associate Engineer" href="https://cloud.google.com/certification/cloud-engineer">Associate Cloud Engineer</a></p>
          </div>
        </div>

      </Container>
      <Container className="docenteFooter">
        <p><a href="https://www.upo.es/postgrado/Master-en-Arquitectura-y-Desarrollo-de-Sistemas-Cloud/#profesorado"><i className="fas fa-chalkboard-teacher"></i> Conoce a todos los docentes</a></p>
      </Container>
    </div>
  </>;
}
function renderContacto() {
  return <>
    <div className="page-contacto">
      <div id="contacto"></div>
      <div className="ball-verde"></div>
      <div className="condiciones">
        <ul>
          <li>
            <i className="fa fa-calendar" /> Del 15 de noviembre de 2024 al 4 de julio de 2025
          </li>
          <li>
            <i className="fa fa-clock" /> Sesiones online, viernes de 16h a 21h
          </li>
          <li>
            <i className="fa fa-euro-sign" /> 4.500 euros. <a href="https://www.upo.es/postgrado/Master-en-Arquitectura-y-Desarrollo-de-Sistemas-Cloud/#precios-becas" target="_blank" rel="noreferrer" title="Programa de pagos">Programa de pagos fraccionados</a>.
          </li>
        </ul>
        <div className="buttons"><Button href="#contacto" title="Quiero más información" > Quiero más información </Button></div>
      </div>
      <Container fluid>
        <Row>
          <Col lg={5}>
            <Container>
            </Container>
          </Col>
          <Col className="formularioWrap" lg={7}>
            <Container>
              <h3>Infórmate sin compromiso</h3>
              <p>Pide información sin compromiso y nos pondremos en contacto contigo para explicarte el programa y la metodología.</p>
              <ContactForm></ContactForm>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}
function renderFooter() {
  return <>
    <div id="footer" className="page-footer">
      <div className="ball-azul"></div>
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={3}>
            <a href="https://www.upo.es" target="_blank" rel="noreferrer"><img src={logoUpo} alt="UPO"></img></a>
          </Col>
          <Col lg={3}>
            {/*<a href="https://www.emergya.com" target="_blank" rel="noreferrer"><img src={logoEmergya} alt="Emergya" style={{ width: "100%", maxWidth: "200px",}}></img></a>*/}
            <a href="https://cloud.google.com/" target="_blank" rel="noreferrer"><img src={googleCloud} alt="Google Cloud" style={{ width: "100%", maxWidth: "400px",}}></img></a>
          </Col>
        </Row>
      </Container>
      <div className="footer_links"><a href="https://twitter.com/csmd_upo" target="_blank" rel="noreferrer" className="twitter_link">Twitter</a>
        <a href="https://www.linkedin.com/company/master-csmd-upo/" target="_blank" className="facebook_link" rel="noreferrer">Linkedin</a></div>
    </div>
  </>;
}
function renderCookiesPolicy() {
  return <>
    <p>Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin cookies los servicios ofrecidos por cualquier página se verían mermados notablemente. Si desea consultar más información sobre qué son las cookies, qué almacenan, cómo eliminarlas, desactivarlas, etc.</p>
    <h3>Cookies utilizadas en este sitio web</h3>
    <p>Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de cookies que hace esta web con el fin de informarle con la máxima exactitud posible.</p>
    <p>Este sitio web utiliza las siguientes cookies de terceros: </p>
    <ul>
      <li>Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google.</li>
      <li>Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones del tipo Me gusta o Compartir.</li>
    </ul>
    <h3>Desactivación o eliminación de cookies</h3>
    <p>En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web. Estas acciones se realizan de forma diferente en función del navegador que esté usando.</p>
    <h4>Notas adicionales</h4>
    <ul>
      <li>Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.</li>
      <li>Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.</li>
      <li>En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las mismas.</li>
      <li>En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Puede consultar información detallada a este respecto <a href="http://safeharbor.export.gov/companyinfo.aspx?id=16626">en este enlace</a>. Si desea información sobre el uso que Google da a las cookies le adjuntamos <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&csw=1">este otro enlace.</a></li>
      <li>Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través de la sección de contacto de la web.</li>
    </ul>
  </>;
}
function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="App">
      <Navigation></Navigation>
      {renderContactButtons()}
      {renderLandpage()}
      {renderEnQueConsiste()}
      {renderObjetivos()}
      {renderParaQuien()}
      {renderOportunidadesEmpleo()}
      {renderComoFunciona()}
      {renderDocentes()}
      {renderContacto()}
      {renderFooter()}
      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Uso de cookies: Este sitio web utiliza cookies para que usted tenga la mejor experiencia de usuario.{" "}
        <span style={{ fontSize: "10px" }}>Si continúa navegando está dando su consentimiento.</span>
        <>
          <Button className="btn-download" variant="primary" onClick={handleShow}> Ver política de cookies </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Política de Cookies</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderCookiesPolicy()}</Modal.Body>
          </Modal>
        </>
      </CookieConsent>
    </div>
  );
}

export default App;
